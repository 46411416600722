.header1 {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    text-align: right;
    color: #333333;
}
.header2 {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    text-align: right;
    direction: rtl;

    font-size: 15pt;
}
.editorCard{
    padding: 10px;
    
}
.bodyMargin{
    padding: 20px;
    text-align: right;
    background-color: white;
}
.titleInput{
    text-align: right;
    border-color: white;
    width: 300px;
}
.titleInputShort{
    text-align: right;
    border-color: white;
    width: 40px;
}
.SelectInput{
    width: 80px;
    text-align: right;
}