@import url('https://fonts.googleapis.com/css?family=Varela+Round&display=swap');
.allPage {
    background-color: #333333;
    width: 1000;
    height: 100%;
}
body {
    background-color: #333333;
  }
.downloadCard {
    width: 100px;
    height: 100px;
    background-color: #cccccc;  
    border-radius: 15px;
}

.title {
    padding-top: 220px;
    color: #999999;
    font-family: 'Varela Round', sans-serif;
    font-size: 35px;
    text-align: center;
}

.middleText {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    color: #999999;
    font-family: 'Varela Round', sans-serif;
    font-size: 20px;
    text-align: center;
}

.topDiv {
    height: 210px;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
    background-size: 1000% 1000%;
    -webkit-animation: rainbow 18s ease infinite;
    -z-animation: rainbow 18s ease infinite;
    -o-animation: rainbow 18s ease infinite;
    animation: rainbow 18s ease infinite;
    clip-path: ellipse(100% 100% at 50% 0);
    -webkit-clip-path: ellipse(100% 100% at 50% 0);
    align-content: center;
    text-align: center;
}

@-webkit-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

@-moz-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

@-o-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

@keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}