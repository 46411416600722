@import url(https://fonts.googleapis.com/css?family=Varela+Round&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bottomText {
    color: black;
    padding-top: 40px;
    text-decoration: none;
}
a:link {
    text-decoration: none;
  }
.bottomDiv {
    height: 110px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    position: -webkit-sticky;
    position: sticky;
    background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
    background-size: 1000% 1000%;
    -webkit-animation: rainbow 18s ease infinite;
    -z-animation: rainbow 18s ease infinite;
    animation: rainbow 18s ease infinite;
    -webkit-clip-path: ellipse(100% 100% at 50% 100%);
            clip-path: ellipse(100% 100% at 50% 100%);
    align-content: center;
    text-align: center;
}

@-webkit-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

@keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}
.allPage {
    background-color: #333333;
    width: 1000;
    height: 100%;
}
body {
    background-color: #333333;
  }
.downloadCard {
    width: 100px;
    height: 100px;
    background-color: #cccccc;  
    border-radius: 15px;
}

.title {
    padding-top: 220px;
    color: #999999;
    font-family: 'Varela Round', sans-serif;
    font-size: 35px;
    text-align: center;
}

.middleText {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    color: #999999;
    font-family: 'Varela Round', sans-serif;
    font-size: 20px;
    text-align: center;
}

.topDiv {
    height: 210px;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
    background-size: 1000% 1000%;
    -webkit-animation: rainbow 18s ease infinite;
    -z-animation: rainbow 18s ease infinite;
    animation: rainbow 18s ease infinite;
    clip-path: ellipse(100% 100% at 50% 0);
    -webkit-clip-path: ellipse(100% 100% at 50% 0);
    align-content: center;
    text-align: center;
}

@-webkit-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

@keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}
.header1 {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    text-align: right;
    color: #333333;
}
.header2 {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    text-align: right;
    direction: rtl;

    font-size: 15pt;
}
.editorCard{
    padding: 10px;
    
}
.bodyMargin{
    padding: 20px;
    text-align: right;
    background-color: white;
}
.titleInput{
    text-align: right;
    border-color: white;
    width: 300px;
}
.titleInputShort{
    text-align: right;
    border-color: white;
    width: 40px;
}
.SelectInput{
    width: 80px;
    text-align: right;
}
