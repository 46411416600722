.bottomText {
    color: black;
    padding-top: 40px;
    text-decoration: none;
}
a:link {
    text-decoration: none;
  }
.bottomDiv {
    height: 110px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    position: sticky;
    background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
    background-size: 1000% 1000%;
    -webkit-animation: rainbow 18s ease infinite;
    -z-animation: rainbow 18s ease infinite;
    -o-animation: rainbow 18s ease infinite;
    animation: rainbow 18s ease infinite;
    clip-path: ellipse(100% 100% at 50% 100%);
    align-content: center;
    text-align: center;
}

@-webkit-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

@-moz-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

@-o-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

@keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}